import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from './Header'
import GovBanner from './GovBanner'
import NavStaticQuery from './NavStaticQuery'
import Footer from './Footer'
import TickerStaticQuery from './TickerStaticQuery'
import styled from 'styled-components'
import './css/slick.min.css'
import '../uswds/css/uswds.min.css'
import './css/uswds-overrides.css'

const Main = styled.main`
  background-color: #ffffff;
  min-height: 600px;
  margin-top: 0;
  padding-top: 10px;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'AMEDD Civilian Corps' },
            {
              name: 'keywords',
              content: 'Army, medical, department, civilian, corps',
            },
          ]}
        />
        <GovBanner />
        <Header />
        <TickerStaticQuery />
        <NavStaticQuery />
        <Main id='main-content'>{children}</Main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React, { Component } from 'react'
import Autocomplete from 'react-autocomplete'
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import './css/search-virtualized.css'
import './css/search.css'
// import { navigate } from 'gatsby'

class Search extends Component {
  static compareViewType(a, b) {
    const aViewtype = a.ViewType.toLowerCase()
    const bViewtype = b.ViewType.toLowerCase()

    const aTitle = a.Title.toLowerCase()
    const bTitle = b.Title.toLowerCase()

    if (aViewtype < bViewtype) return -1
    if (aViewtype > bViewtype) return 1

    if (aTitle < bTitle) return -1
    if (aTitle > bTitle) return 1
    return 0
  }

  constructor(props) {
    super(props)

    this.cellHeightCache = new CellMeasurerCache({
      defaultHeight: 40,
      fixedWidth: true,
    })

    const searchItems = this.props.SearchData.map(item => item.node).filter(
      fItem =>
        (fItem.ViewType === 'Page' && fItem.Status !== 'Archived') ||
        fItem.ViewType === 'Document' ||
        fItem.ViewType === 'Awards' ||
        fItem.ViewType === 'Photo' ||
        fItem.ViewType === 'Message'
    )

    searchItems.sort(Search.compareViewType)

    const searchFiles = this.props.SearchFiles.map(item => item.node).filter(
      filteredItem => {
        if (
          searchItems.find(x => x.Title === filteredItem.base) !== undefined
        ) {
          return filteredItem
        }

        return null
      }
    )

    this.state = {
      value: '',
      files: searchFiles,
      searchItems: searchItems,
    }

    this.renderMenu = this.renderMenu.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  renderMenu(items) {
    this.cellHeightCache.clearAll()

    const rowRenderer = ({ key, index, parent, style }) => {
      const Item = items[index]
      const onMouseDown = e => {
        if (e.button === 0) {
          Item.props.onClick(e)
        }
      }

      return (
        <CellMeasurer
          cache={this.cellHeightCache}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          {Item !== undefined
            ? React.cloneElement(Item, {
                style,
                key,
                onMouseEnter: null,
                onMouseDown,
              })
            : null}
        </CellMeasurer>
      )
    }

    return (
      <List
        width={350}
        rowHeight={45}
        height={300}
        rowCount={
          this.props.SearchData.map(item => item.node).filter(
            fItem =>
              (fItem.ViewType === 'Page' && fItem.Status !== 'Archived') ||
              fItem.ViewType === 'Document' ||
              fItem.ViewType === 'Awards' ||
              fItem.ViewType === 'Photo' ||
              fItem.ViewType === 'Message'
          ).length
        }
        rowRenderer={rowRenderer}
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          paddingLeft: '10px',
          cursor: 'pointer',
        }}
      />
    )
  }

  onSelect(value) {
    const valArr = value.split('/')
    const fileFound = this.state.files.find(item => item.base === valArr[0])

    // If file is found then download it
    if (fileFound) {
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = fileFound.publicURL
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      // const otherThanDocument = this.state.searchItems.find(
      //   item => item.ID === Number(valArr[valArr.length - 1])
      // )
      // if (otherThanDocument !== undefined) {
      //   if (otherThanDocument.ViewType === 'Awards') {
      //     console.log(
      //       'awards',
      //       `/CivCorpsAward/${valArr[0].replace(/ /g, '-').toLowerCase()}`
      //     )
      //   } else if (otherThanDocument.ViewType === 'Message') {
      //     console.log(
      //       valArr[0],
      //       `/CivCorpsMessage/${valArr[0].replace(/ /g, '-').toLowerCase()}`
      //     )
      //   }
      // }
    }
  }

  clearSearch(e) {
    e.preventDefault()

    this.setState({
      value: '',
    })
  }

  render() {
    let currentViewType = 'Awards'
    let isCurrentViewType = true
    let count = 0
    return (
      <>
        <Autocomplete
          getItemValue={item => `${item.Title}/${item.ID}`}
          items={this.state.searchItems}
          renderItem={(item, isHighlighted) => {
            if (currentViewType === item.ViewType) {
              count = count + 1
            } else {
              currentViewType = item.ViewType
              count = 0
            }

            if (count > 0) {
              isCurrentViewType = false
            } else {
              isCurrentViewType = true
            }

            return (
              <div
                style={{
                  backgroundColor: isHighlighted ? 'lightgray' : 'white',
                  width: '400px',
                }}
                key={item.ID}
              >
                {isCurrentViewType ? (
                  <span
                    style={{
                      display: 'block',
                      backgroundColor: 'maroon',
                      color: 'white',
                      height: '24px',
                    }}
                  >
                    {item.ViewType}
                  </span>
                ) : null}
                {item.ViewType === 'Document' || item.ViewType === 'Photo'
                  ? `${item.Caption}`
                  : `${item.Title}`}
              </div>
            )
          }}
          shouldItemRender={(item, value) => {
            if (item.ViewType === 'Document' || item.ViewType === 'Photo') {
              return (
                item.Caption.toLowerCase().indexOf(value.toLowerCase()) > -1
              )
            }

            return item.Title.toLowerCase().indexOf(value.toLowerCase()) > -1
          }}
          renderMenu={this.renderMenu}
          inputProps={{
            style: {
              width: '120px',
              height: '26px',
              borderRadius: '9px',
            },
            placeholder: 'search',
          }}
          value={this.state.value}
          onChange={e => this.setState({ value: e.target.value })}
          onSelect={value => this.onSelect(value)}
          style={{ width: '300px', marginLeft: '5px' }}
        />
      </>
    )
  }
}

export default Search

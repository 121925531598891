import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Search from '../components/Search'

const SearchStaticQuery = () => (
  <StaticQuery
    query={graphql`
      query getSearchData {
        allBtswebViewtypeJson {
          edges {
            node {
              Author
              Status
              ViewType
              ID
              Type
              Title
              Caption
              ApprovalStatus
            }
          }
        }
        allFile {
          edges {
            node {
              name
              extension
              publicURL
              base
            }
          }
        }
      }
    `}
    render={data => (
      <Search
        SearchData={data.allBtswebViewtypeJson.edges}
        SearchFiles={data.allFile.edges}
      />
    )}
  />
)

export default SearchStaticQuery;

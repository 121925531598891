import moment from 'moment'

export const navItems = [
  {
    name: 'Dashboard',
    icon: 'FaHome',
  },
]

export function formatDate(date, withTime) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const dateF = new Date(date)
  const day = dateF.getDate()
  const monthIndex = dateF.getMonth()
  const year = dateF.getFullYear()
  const time = dateF.toLocaleTimeString()
  if (withTime) return `${day} ${monthNames[monthIndex]} ${year} ${time}`

  return `${day} ${monthNames[monthIndex]} ${year}`
}

export function bytesToSize(str) {
  let bytesInitial = [] // char codes

  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i)
    bytesInitial = bytesInitial.concat([code])
  }
  const bytes = bytesInitial.join(', ')
  return bytes
}

export function count(obj) {
  return Object.keys(obj).length
}

export function checkDate(dt) {
  const newDate = moment('1753-01-01T00:00:00')
  if (dt === '0001-01-01T00:00:00') {
    return newDate
  }
  return moment(dt)
}

export function getJulianDate() {
  const date2 = new Date()
  const date1 = new Date(`1/1/${date2.getFullYear().toString()}`)
  // const date2 = new Date();
  const timeDiff = Math.abs(date2.getTime() - date1.getTime())
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  // alert(diffDays);
  return `${date2.getFullYear()}-${diffDays}-`
}

const a = [
  '',
  'one ',
  'two ',
  'three ',
  'four ',
  'five ',
  'six ',
  'seven ',
  'eight ',
  'nine ',
  'ten ',
  'eleven ',
  'twelve ',
  'thirteen ',
  'fourteen ',
  'fifteen ',
  'sixteen ',
  'seventeen ',
  'eighteen ',
  'nineteen ',
]
const b = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
]

export function inWords(num) {
  if(num === 0){
    return "one"
  }
  if ((num = num.toString()).length > 9) return 'overflow'
  const n = `000000000${num}`
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) return
  let str = ''
  str +=
    n[5] !== 0
      ? `${(str !== '' ? 'and ' : '') +
          (a[Number(n[5])] || `${b[n[5][0]]} ${a[n[5][1]]}`)}`
      : ''

  return str
}

export function arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = [].slice.call(new Uint8Array(buffer))

  bytes.forEach(b => (binary += String.fromCharCode(b)))

  return window.btoa(binary)
}

export function toUTF8Array(str) {
  const utf8 = []
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i)
    if (charcode < 0x80) utf8.push(charcode)
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f))
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      )
    } else {
      // surrogate pair
      i++
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode =
        0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff))
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      )
    }
  }
  return utf8
}

export function hexToBase64(str) {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, '')
        .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
        .replace(/ +$/, '')
        .split(' ')
    )
  )
}

export function sortObject(o) {
  var sorted = {},
    key,
    a = []

  for (key in o) {
    if (o.hasOwnProperty(key)) {
      a.push(key)
    }
  }

  a.sort()

  for (key = 0; key < a.length; key++) {
    sorted[a[key]] = o[a[key]]
  }
  return sorted
}

import React, { Component } from 'react'
import styled from 'styled-components'

const Marquee = styled.div`
  /* Make it a marquee */
  .marquee {
    // width: 450px;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    animation: marquee 50s linear infinite;
    background-color: #780032;
    color: whitesmoke;
    font-size: 14px;
    a {
      color: whitesmoke;
    }
  }

  .marquee:hover {
    animation-play-state: paused;
  }

  .marquee span {
    margin-right: 50px;
  }

  /* Make it move */
  @keyframes marquee {
    0% {
      text-indent: 118.5em;
    }
    100% {
      text-indent: -105em;
    }
  }

  /* Make it pretty */
  .microsoft {
    padding-left: 1.5em;
    position: relative;
    font: 16px 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  }

  /* ::before was :before before ::before was ::before - kthx */
  .microsoft:before,
  .microsoft::before {
    z-index: 2;
    content: '';
    position: absolute;
    top: -1em;
    left: -1em;
    width: 0.5em;
    height: 0.5em;
    box-shadow: 1em 1.25em 0 #f65314, 1.6em 1.25em 0 #7cbb00,
      1em 1.85em 0 #00a1f1, 1.6em 1.85em 0 #ffbb00;
  }

  .microsoft:after,
  .microsoft::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2em;
    height: 2em;
    background-image: linear-gradient(90deg, white 70%, rgba(255, 255, 255, 0));
  }

  /* Style the links */
  .vanity {
    color: #333;
    text-align: center;
    font: 0.75em 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  }

  .vanity a,
  .microsoft a {
    color: #1570a6;
    transition: color 0.5s;
    text-decoration: none;
  }

  .vanity a:hover,
  .microsoft a:hover {
    color: #f65314;
  }
`

class Ticker extends Component {
  marqueeItems = items => {
    return items.map((item, i) =>
      item.Status === 'Active' ? (
        item.Title ? (
          <a
            href={
              item.Title === '/helpful-links'
                ? '/static/TSG45%20Book%20of%20the%20Month-25e9b955272688ea58e434d11b248991.pdf'
                : item.Title
            }
            key={i}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{item.Caption}</span>
          </a>
        ) : (
          <span key={i}>{item.Caption}</span>
        )
      ) : null
    )
  }

  render() {
    const tickerData = this.props.TickerData.map(item => {
      return item.node
    })

    return (
      <Marquee>
        <p className="marquee">{this.marqueeItems(tickerData)}</p>
      </Marquee>
    )
  }
}

export default Ticker

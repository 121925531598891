import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import logo from '../images/civCorps-logo.gif'

const Footer = () => (
  <footer className='usa-footer usa-footer-big site' role='contentinfo'>
    <div className='usa-grid usa-footer-return-to-top'>
      {/*  eslint-disable-next-line */}
      <a href='#'>Return to top</a>
    </div>
    <div className='usa-footer-primary-section'>
      <div className='usa-grid'>
        <nav className='usa-footer-nav'>
          <ul className='usa-unstyled-list usa-width-one-fourth usa-footer-primary-content'>
            <li className='usa-footer-primary-link'>
              <h4>Links</h4>
            </li>
            <li className='usa-footer-secondary-link'>
              <a target='_blank' href='https://www.foia.gov/' rel='noopener noreferrer'>
                Freedom of Information Act <FaExternalLinkAlt />
              </a>
            </li>
            <li className='usa-footer-secondary-link'>
              <a target='_blank' href='https://www.goarmy.com/' rel='noopener noreferrer'>
                Go Army <FaExternalLinkAlt />
              </a>
            </li>
            <li className='usa-footer-secondary-link'>
              <a target='_blank' href='https://www.nlrb.gov/no-fear-act' rel='noopener noreferrer'>
                No Fear Act Data <FaExternalLinkAlt />
              </a>
            </li>
            <li className='usa-footer-secondary-link'>
              <a href='https://www.cs.amedd.army.mil/ig.aspx'>Office of the Inspector General</a>
            </li>
            <li className='usa-footer-secondary-link'>
              <a href='https://www.cs.amedd.army.mil/privacy.aspx'>Privacy Policy</a>
            </li>
            <li className='usa-footer-secondary-link'>
              <a
                className='external-link'
                target='_blank'
                href='https://ke.army.mil/rfi/popup.aspx?pg=HomePage'
                rel='noopener noreferrer'
              >
                Request For Information <FaExternalLinkAlt />
              </a>
            </li>
            <li className='usa-footer-secondary-link'>
              <a className='external-link' target='_blank' href='https://www.usa.gov' rel='noopener noreferrer'>
                USA.gov: The U.S. Government’s Official Web Portal <FaExternalLinkAlt />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div className='usa-footer-secondary_section'>
      <div className='usa-grid'>
        <div className='usa-footer-logo usa-width-one-half'>
          <img className='usa-footer-logo-img' src={logo} alt='' />
          <h3 className='usa-footer-logo-heading'>AMEDD Civilian Corps</h3>
          <h3 className='usa-footer-big-logo-heading'>
            <span> U.S. Army Medical Center of Excellence</span>
          </h3>
        </div>
        <div className='usa-footer-contact-links usa-width-one-half'>
          {/*FSP@BTS: NO Social Media Links for now */}
          {/* <IconContext.Provider value={{ color: 'black', size: '32' }}>
            <a
              target="_blank"
              href="https://www.facebook.com/MedicalCoE/"
              rel="noopener noreferrer"
            >
              <FaFacebook style={{ marginRight: '10px' }} />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/MedicalCoE"
              rel="noopener noreferrer"
            >
              <FaTwitter style={{ marginRight: '10px' }} />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCx0h09-T6ZlARWGqvPgAhFg"
              rel="noopener noreferrer"
            >
              <FaYoutube style={{ marginRight: '10px' }} />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/medcoe/"
              rel="noopener noreferrer"
            >
              <FaLinkedin style={{ marginRight: '10px' }} />
            </a>
          </IconContext.Provider> */}
          <h3 className='usa-footer-contact-heading'>Connect With Us</h3>
          <address>
            <div className='usa-footer-primary-content usa-footer-contact_info'>
              <p>
                <a href='tel:+1210-808-3025'>210-808-3025/6982</a>
              </p>
            </div>
            <div className='usa-footer-primary-content usa-footer-contact_info'>
              <p>
                <a href='mailto:usarmy.jbsa.medical-coe.mbx.civilian-corps-chief@army.mil'>
                  usarmy.jbsa.medical-coe.mbx.civilian-corps-chief@army.mil
                </a>
              </p>
            </div>
          </address>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer

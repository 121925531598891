import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import logo from '../images/civCorps-logo.gif'
import armyLogo from '../images/army-logo.png'
import bg from '../images/header-bg.png'

const FancyHeader = styled.div`
  background: #8e9eab; /* fallback for old browsers */
  min-height: 200px;
  padding: 40px 0;
  background-image: url('${props => props.bg}');
  background-repeat: no-repeat;
  background-size: cover
`
const GraphicHeader = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;

  .header_h1 {
    margin: 0;
    font-size: 2.5rem;
  }
  .header_subtext {
    margin: 0;
    font-size: 2rem;
    font-size: 46px;
    color: #750030;
    font-weight: 900;
    padding-top: 4px;
  }
  .header_subtext-three {
    margin: 0;
    font-size: 3rem;
    color: #000000;
    font-weight: 700;
    font-style: italic;
  }
  @media only screen and (max-width: 480px) {
    .logo-army,
    .header_subtext,
    .header_subtext-three {
      display: none;
    }
  }
`

const Header = () => (
  <FancyHeader bg={bg}>
    <div className='usa-grid'>
      <GraphicHeader>
        <img className='logo-army' src={armyLogo} alt='Army logo' height='100' width='80' />
        <div className='heading-container' onClick={() => navigate("/")}>
          <h1 className='header_h1'>Army Medical Department Civilian Corps</h1>
          <h2 className='header_subtext'>Army Medicine</h2>
          <h2 className='header_subtext-three'>Our Profession</h2>
        </div>
          <img className='logo-civ' src={logo} alt='Civilian Corps logo' height='100' width='100' onClick={() => navigate("/")} />
      </GraphicHeader>
    </div>
  </FancyHeader>
)

export default Header

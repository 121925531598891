import React, { Component } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import SearchStaticQuery from './SearchStaticQuery'
import { inWords } from '../utilities/Utility'
import close from '../uswds/img/close.svg'

const MegaMenuColumn = styled.div`
  button {
    color: #212121;
  }
`

const MegMenuLink = styled.a`
  :hover {
    cursor: pointer;
  }
`

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addClass: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    }
  }

  getLiveMenu = () => {
    const topLevelMenus = this.props.nav.filter(item => {
      if (item.node.ID === item.node.Parent) {
        return item
      }
      return null
    })

    const tlMenus = topLevelMenus.map(item => item.node)

    // Sort by ID
    tlMenus.sort(function(a, b) {
      return a.ID - b.ID
    })

    return tlMenus.map((menu, index) => (
      <li key={`${menu.ID}-${menu.Title}`}>
        <button
          className="usa-accordion-button usa-nav-link"
          aria-expanded={this.state.addClass[index]}
          aria-controls={`extended-mega-nav-section-${inWords(menu.ID).trim()}`}
          key={`button-${menu.ID}-${menu.Title}`}
          onClick={e => this.handleMenuClick(e, index, menu.ID)}
        >
          <span key={`span-${menu.ID}-${menu.Title}`}>{menu.Title}</span>
        </button>
        {menu.ID === 0 ? (
          <div
            id="extended-mega-nav-section-one"
            className="usa-nav-submenu usa-megamenu usa-grid-full"
            aria-hidden="true"
          />
        ) : (
          <div
            id={`extended-mega-nav-section-${inWords(menu.ID).trim()}`}
            className="usa-nav-submenu usa-megamenu usa-grid-full"
            aria-hidden={!this.state.addClass[index]}
            key={`div-${menu.ID}-${menu.Title}`}
          >
            {this.returnLI(menu.ID)}
          </div>
        )}
      </li>
    ))
  }

  returnLI = id => {
    const preFilteredSub = this.props.nav.map(item => item.node)
    let filteredSub = preFilteredSub.filter(
      item => item.Parent === id && item.ID !== item.Parent
    )

    // break down main array into 3
    let arrays = []
    const size = 3

    while (filteredSub.length > 0) arrays.push(filteredSub.splice(0, size))

    return arrays.map(array => (
      <MegaMenuColumn
        className="usa-megamenu-col"
        key={`div-${array[0].Link}${array[0].Title}`}
      >
        <ul key={`UL-${array[0].Link}${array[0].Title}`}>
          {array.map((lnk, index) => {
            return (
              <li key={`$lnk.Link}${lnk.Title}-${index}`}>
                <MegMenuLink onClick={e => this.handleSubMenuClick(e, lnk)}>
                  {lnk.Title}
                </MegMenuLink>
              </li>
            )
          })}
        </ul>
      </MegaMenuColumn>
    ))
  }

  handleMenuClick = (e, id, menuId) => {
    e.preventDefault()

    const newClass = this.state.addClass.map((item, index) => index === id)

    if (id === 0) {
      navigate('/')
      this.setState({
        addClass: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      })
    } else {
      this.setState({
        addClass: newClass,
      })
    }
    this.forceUpdate()
  }

  handleSubMenuClick = (e, lnk) => {
    e.preventDefault()

    this.setState({
      addClass: [false, false, false, false, false, false, false, false, false],
    })
    this.forceUpdate()

    navigate(lnk.Link)
  }

  render() {
    return (
      <header
        className="usa-header usa-header-basic usa-header-basic-megamenu"
        role="banner"
      >
        <div className="usa-nav-container" style={{ maxWidth: '1535px' }}>
          <div className="usa-navbar" style={{ height: '53px' }}>
            <button className="usa-menu-btn">Menu</button>
          </div>

          <nav role="navigation" className="usa-nav">
            <div className="usa-nav-inner">
              <button className="usa-nav-close">
                <img src={close} alt="close" />
              </button>
              <ul className="usa-nav-primary usa-accordion">
                {this.getLiveMenu()}
              </ul>

              <SearchStaticQuery />
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

export default Navigation

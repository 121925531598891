import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Ticker from './Ticker'

const TickerStaticQuery = () => (
  <StaticQuery
    query={graphql`
      query getTickerText {
        allBtswebViewtypeJson(filter: { ViewType: { eq: "Ticker" } }) {
          edges {
            node {
              Author
              Status
              ViewType
              ID
              Type
              Title
              Caption
              ApprovalStatus
            }
          }
        }
      }
    `}
    render={data => <Ticker TickerData={data.allBtswebViewtypeJson.edges} />}
  />
)

export default TickerStaticQuery;

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Navigation from './Navigation'

const NavStaticQuery = () => (
  <StaticQuery
    query={graphql`
      query staticMenu {
        allBtswebMenuJson {
          edges {
            node {
              ID
              Content_ID
              Title
              Link
              Parent
              SortOrder
            }
          }
        }
      }
    `}
    render={data => <Navigation nav={data.allBtswebMenuJson.edges} />}
  />
)

export default NavStaticQuery;
